import * as React from 'react';
import {Link} from 'gatsby';
import SEO from '../components/SEO/SEO';
import Footer from '../components/footer/Footer';
import SiteNavbar from '../components/header/siteNavbar/SiteNavbar';
import '../styles/main.scss';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const NotFoundPage = () => (
    <React.Fragment>
        <SEO/>
        <SiteNavbar/>
        <main className="soul-space-stack-both-3-xl">
            <div className="soul-empty-state soul-empty-state--not-found soul-space-stack-both-3-xl">
                <div className="soul-empty-state__image"></div>
                <strong className="soul-empty-state__title">Page not found</strong>
                <p className="soul-empty-state__description">You've hit the void. <Link to="/" className={'c-link'}>Go back.</Link></p>
            </div>
        </main>
        <Footer/>
    </React.Fragment>
);

export default NotFoundPage;
